<template>
<section class="profile">
  <pageLoader v-if="loadershow" :loadingText="'Traitement des données en cours'"/>
  <div class="content-wrapper">
    <div class="page-header">
      <h3 class="page-title">
        Profil
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);"></a></li>
          <li class="breadcrumb-item active" aria-current="page">Profil</li>
        </ol>
      </nav>
    </div>
    <div v-if="loadermyinfo" class="jumping-dots-loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div v-else class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-4">
                <div class="border-bottom text-center pb-4">
                  <!-- image profil -->
                  <span v-b-modal.image>
                    <picture v-if="myInfos.image">
                        <source class="img-lg rounded-circle mb-3"  :srcset="myInfos.image.content.webpImage" type="image/webp">
                        <img draggable="false" class="img-lg rounded-circle mb-3"  :src="myInfos.image.content.originalImage" alt="Card image cap">
                    </picture>
                    <img v-else src="@/assets/images/import/default-user.png" class="img-lg rounded-circle mb-3" alt="">
                  </span>

                  <!-- modal image de profil -->
                  <b-modal id="image" size="md" hide-header hide-footer>

                    <div v-if="loadermyinfo2" class="jumping-dots-loader">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>

                    <template v-else>
                      <picture v-if="myInfos.image ">
                          <source class=""  :srcset="myInfos.image.content.webpImage" type="image/webp">
                          <img draggable="false" class="" :src="myInfos.image.content.originalImage" alt="Card image cap">
                      </picture>
                      <img v-else src="@/assets/images/import/default-user.png" class="" alt="">
                    </template>
                    
                    <!-- ajouter une nouvelle image de profil/ components--add-image -->
                    <div class="d-block d-sm-flex justify-content-center">
                      <add-image :modal="'Ajout image'" @upload-image="loadermyinfo2 = true"  @saveImage="loadermyinfo2=true,saveImage($event['ulid']),$bvModal.hide('image')" />
                    </div>
                  </b-modal>

                  <p>Content de vous revoir " {{myInfos.firstname}} {{myInfos.lastname}} " </p>
                  <div class="d-block d-sm-flex justify-content-between">
                  </div>
                </div>
                <div class="py-4">
                  <p class="clearfix">
                    <span class="float-left">
                      <h3>Informations personelles</h3>
                    </span>
                    <span class="float-right text-muted">
                      <a href="javascript:void(0);"></a>
                    </span>
                  </p>
                  <hr>
                  <p class="clearfix">
                    <span class="float-left">
                      Nom
                    </span>
                    <span class="float-right text-muted">
                      {{myInfos.lastname}}
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Prénom
                    </span>
                    <span class="float-right text-muted">
                      {{myInfos.firstname}}
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Téléphone
                    </span>
                    <span class="float-right text-muted">
                      {{myInfos.tel}}
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Email
                    </span>
                    <span class="float-right text-muted">
                      {{myInfos.email}}
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      <h3>Adresse</h3>
                    </span>
                    <span class="float-right text-muted">
                      <a href="javascript:void(0);"></a>
                    </span>
                  </p>
                  <hr>
                  <p class="clearfix">
                    <span class="float-left">
                      Voie
                    </span>
                    <span class="float-right text-muted">
                      {{way}}
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Ville
                    </span>
                    <span class="float-right text-muted">
                      {{city}}
                    </span>
                  </p>
                  <!-- <p class="clearfix">
                    <span class="float-left">
                      Département
                    </span>
                    <span class="float-right text-muted">
                      {{department}}
                    </span>
                  </p> -->
                  <p class="clearfix">
                    <span class="float-left">
                      Pays
                    </span>
                    <span class="float-right text-muted">
                      {{country}}
                    </span>
                  </p>
                  <p class="clearfix">
                    <span class="float-left">
                      Code postal
                    </span>
                    <span class="float-right text-muted">
                      {{postalCode}}
                    </span>
                  </p>
                </div>
                <b-button variant="gradient-primary btn-block" v-b-modal.edit>Modifier</b-button>
                <b-button variant="gradient-link btn-block" @click="forgotPassword()">Modifier mon mot de passe</b-button>
                <b-modal id="edit" hide-footer hide-header>
                        <div v-if="loadermyinfo" class="jumping-dots-loader">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <form v-else class="forms-sample" @submit.prevent="handleSubmit">                        
                        <div class="row">
                                    <div class="col-md-6 col-sm-12">
                                        <h4>Informations</h4>
                                        <b-form-group id="pseudo" label="Nom d'utilisateur (pseudo)" label-for="pseudo">
                                            <b-form-input
                                            id="pseudo"
                                            name="pseudo"
                                            v-model="pseudo"
                                            :state="$v.pseudo.$dirty ? !$v.pseudo.$error : null"
                                            aria-describedby="pseudo"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="pseudo">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>


                                        <!-- firstname -->
                                        <b-form-group id="firstname" label="Nom" label-for="firstname">
                                            <b-form-input
                                            id="firstname"
                                            name="firstname"
                                            v-model="firstname"
                                            :state="$v.firstname.$dirty ? !$v.firstname.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- lastname -->
                                        <b-form-group id="lastname" label="Prénom" label-for="lastname">
                                            <b-form-input
                                            id="lastname"
                                            name="lastname"
                                            v-model="lastname"
                                            :state="$v.lastname.$dirty ? !$v.lastname.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- tel -->
                                        <b-form-group id="tel" label="Numéro téléphone" label-for="tel">
                                            <b-form-input
                                            id="tel"
                                            name="tel"
                                            v-model="tel"
                                            :state="$v.tel.$dirty ? !$v.tel.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <h4>Adresse</h4>
                                        <!-- adresse Voie -->
                                        <b-form-group id="wayAddress" label="Voie" label-for="wayAddress">
                                            <b-form-input
                                            id="wayAddress"
                                            name="wayAddress"
                                            v-model="way"
                                            :state="$v.way.$dirty ? !$v.way.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                        
                                        <!-- adresse ville -->
                                        <b-form-group id="city" label="Ville" label-for="city">
                                            <b-form-input
                                            id="city"
                                            name="city"
                                            v-model="city"
                                            :state="$v.city.$dirty ? !$v.city.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- Adresse code postal -->
                                        <b-form-group id="postalCode" label="Code Postal" label-for="postalCode">
                                            <b-form-input
                                            id="postalCode"
                                            name="postalCode"
                                            v-model="postalCode"
                                            :state="$v.postalCode.$dirty ? !$v.postalCode.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                        <!-- adresse département  -->
                                        <!-- <b-form-group id="department" label="Département" label-for="department">
                                            <b-form-input
                                            id="department"
                                            name="department"
                                            v-model="department"
                                            :state="$v.department.$dirty ? !$v.department.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group> -->

                                        <!-- adresse pays  -->
                                        <b-form-group id="country" label="Pays" label-for="country">
                                            <b-form-input
                                            id="country"
                                            name="country"
                                            v-model="country"
                                            :state="$v.country.$dirty ? !$v.country.$error : null"
                                            aria-describedby="input-1-live-feedback"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="input-1-live-feedback">
                                            Ce champ est obligatoire.
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </div>
                                </div>
                        <hr>
                        <div class="d-flex justify-content-end">
                            <a class="btn btn-link mx-3" @click="$bvModal.hide('edit')">Annuler</a>
                            <button class="btn btn-gradient-primary" type="submit">Enregistrer</button>
                        </div>
                    </form>
                </b-modal>
              </div>
              <div class="col-lg-8">
                <div class="d-lg-flex justify-content-between">
                  <div>
                    <h3 class="mt-3 mt-lg-0">{{myInfos.pseudo}}</h3>
                  </div>
                  <div class="mt-3 mt-lg-0">
                    <!-- <b-button variant="outline-secondary btn-icon" @click="newsletter()"  >
                      <i class="fa fa-paper-plane"></i>
                    </b-button> -->
                    <b-button variant="gradient-primary" @click="myorders()">Toutes les commandes</b-button>
                  </div>
                </div>
                <div class="mt-4 py-2 border-top border-bottom">
                  <ul class="nav profile-navbar">
                    <li class="nav-item">
                      <a class="nav-link active" href="javascript:void(0);">
                        <i class="mdi mdi-newspaper"></i>
                        Mes dernières commandes
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="profile-feed">
                  <b-table 
                  style="color:grey"
                  :items="items" 
                  id="table-list" 
                  responsive :per-page="perPage" 
                  :current-page="currentPage" 
                  :fields="fields" 
                  :sort-by.sync="sortBy" 
                  :sort-desc.sync="sortDesc"
                  :filter="search">

                    <template #cell(type)="row">
                      <span v-if="row.item.purchases[0]" @click="ordersDetails(row.item.ulid)">
                        <span class="badge badge-outline-default badge-pill" v-if="row.item.purchases[0].product"><i class=" mdi mdi-cart mr-2"></i>Achat</span>
                        <span class="badge badge-outline-default badge-pill" v-else-if="row.item.purchases[0].service"><i class="mdi mdi-calendar mr-2"></i>Réservation</span>
                        <span v-else>Vide</span>
                    </span>
                    </template>

                    <template #cell(date)="row">
                      <span v-if="row.item">{{row.item.purchases[0].datepurchase.substring(0,11)}}</span>
                    </template>

                    <template #cell(status)="row">
                      <span class="badge badge-primary" v-if="row.item.status==='Préparation'">Préparation</span>
                      <span class="badge badge-success" v-else-if="row.item.status==='Livraison'">Livraison</span>
                    </template>

                </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>
</template>

<script>
import axios from 'axios'
import { validationMixin } from 'vuelidate'
import { required} from 'vuelidate/lib/validators'
import addImage from '../components/functions/add-image.vue'
import pageLoader from '../components/pageLoader.vue'
export default {
    name: 'profil',
    mixins: [validationMixin],
    components:{
      addImage,
      pageLoader
    },
    data () {
      return {
        loadermyinfo:true,
        loadermyinfo2:false,
        items: [],
        ulid:"",
        fields: [
          { key: 'type', sortable: true },
          { key: 'date', sortable: true },
          { key: 'status', sortable: true }
        ],
        perPage: 10,
        isload:true,
        sortBy: 'name',
        currentPage: 1,
        sortDesc: false,
        sortByFormatted: true,
        filterByFormatted: true,
        search:'',
        //myinfos
        myinfos:{},
        pseudo:'',
        email:'',
        firstname:'',
        lastname:'',
        tel:'',
        way:'',
        city:'',
        department:'',
        country:'',
        postalCode:'',
        loadershow:false
      }
    },
    validations: {
        pseudo: {
            required
        },

        firstname: {
            required
        },
        lastname: {
            required
        },
        tel: {
            required
        },
        way:{
          required
        },
        city: {
          required
        },
        // department: {
        //   required
        // },
        country: {
          required
        },
        postalCode: {
          required
        }
    },
    methods:{
      getMyOrders () {
            axios.get('myorders', {
             headers: {
                 'X-AUTH-TOKEN': localStorage.getItem('token')
             }   
            })
            .then(resGetMyOrders => {
                this.items= resGetMyOrders.data.result
            })
            .catch(errGetMyOrders => console.log({errGetMyOrders}))
        },
        ordersDetails(ulid) {
            this.$router.push({
            name:"ordersDetails",
            params: {
                ulid: ulid
            }
            })
        },
        getMyInfos() {
          axios.get("myinfos",{
            headers:{
              'X-AUTH-TOKEN':localStorage.getItem('token')
              }
              })
              .then(
                  resmyinfo => {
                      this.myInfos=resmyinfo.data
                      console.log('myinfos',this.myInfos);
                      this.setdata(resmyinfo.data)
                      this.loadermyinfo = false
                  }
              )
              .catch(
                  errmyinfo => {
                      console.log(errmyinfo)
                      this.loadermyinfo = false
                  }
              )
        },
         setdata(info){
           this.pseudo =info.pseudo
           this.firstname =info.firstname
           this.lastname =info.lastname
           this.tel =info.tel
            this.ulid = info.ulid
            this.city = info.address.mainaddress.city;                        
            this.country = info.address.mainaddress.country;
            this.way = info.address.mainaddress.line1;
            this.department =info.address.mainaddress.state
            this.postalCode =info.address.mainaddress.postal_code            

        },
        handleSubmit() {
            this.$v.$touch()
            if (this.$v.$anyError) {
              return
            }
            this.putMyInfos()
        },
        putMyInfos () {
          this.$bvModal.hide('edit')
          this.loadershow=true
          axios.put('myinfos/'+this.ulid,
          {
                pseudo:this.pseudo,
                firstname:this.firstname,
                lastname:this.lastname,
                newsletter: true,
                tel:this.tel,
                address:{
                  "mainaddress":{
                    line1:this.way,
                    city:this.city,
                    state:this.department,
                    country:this.country,
                    postal_code:this.postalCode
                    }
                }

            },
                 {
                     headers:
                     {
                        'X-AUTH-TOKEN':localStorage.getItem("token")
                    }
                },
            )
            .then(res=> {
              console.log(res)
              location.reload()
            })
            .catch(err => {
                this.loadershow=false
              console.log(err)})
        },
        forgotPassword() {
          this.$router.push ({
            name: "forgotPassword"
          })
        },
        newsletter(){
          this.$router.push({
            name: "newsletter"
          })
        },
        saveImage(ulidImage){
          this.loadermyinfo2=true
          axios.put(`myinfos/${this.ulid}`,
          {
            image: ulidImage,
          },
          {
            headers: {
                'X-AUTH-TOKEN': localStorage.getItem('token')
            }
          })
          .then(()=> {
            window.location.reload()
          })
          .catch(err => {
            console.log ('errorPUT', err)
            alert('Une erreur est survenue')
            this.loadermyinfo2=false
            })
        },
        myorders(){
          this.$router.push({
            name:"myorders"
          })
        }
    },
    mounted(){
      this.getMyOrders()
      this.getMyInfos()
    }
}
</script>
<style scoped>
#image img{
  max-width: 100%;
  object-fit: cover;
}
</style>